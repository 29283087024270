import React, { useState, useRef } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { LAYOUT } from "../constants"
import { H1 } from "../components/Heading"

const Root = styled.div`
  position: relative;
  max-width: 906px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  padding: 0 0 40px 0;
`

interface IImg {
  fluid?: object
  fixed?: object
}

const HeroImg = styled(Img)<IImg>`
  width: 100%;
  max-width: 300px;
  margin: 0 auto 0 auto;
`

const Logo = styled(Img)<IImg>`
  width: 200px;
  margin: 0 auto 40px auto;
`

const CopyContainer = styled.div`
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
`

const StyledBodyText = styled(BodyText)`
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    p,
    a,
    strong {
      font-size: 1.8rem;
    }
  }
`

interface PromoHero {
  body: React.ReactElement
  promoKey: string
  heading: string
  rest?: object
}

const PromoHero: React.FC<PromoHero> = ({
  body = null,
  heading = null,
  promoKey = "boomCycle",
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query PromoHeroQuery {
      boomCycle: file(relativePath: { eq: "promoHero/boom-cycle.png" }) {
        childImageSharp {
          fluid(maxWidth: 205, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      kobox: file(relativePath: { eq: "promoHero/kobox.png" }) {
        childImageSharp {
          fluid(maxWidth: 205, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      barrecore: file(relativePath: { eq: "promoHero/barrecore.png" }) {
        childImageSharp {
          fluid(maxWidth: 281, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      boomCycleLogo: file(relativePath: { eq: "withThanksTo/boom-cycle.png" }) {
        childImageSharp {
          fixed(width: 300, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      koboxLogo: file(relativePath: { eq: "withThanksTo/kobox.png" }) {
        childImageSharp {
          fixed(width: 300, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      barrecoreLogo: file(relativePath: { eq: "withThanksTo/barrecore.png" }) {
        childImageSharp {
          fixed(width: 300, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <HeroImg
        loading={"eager"}
        fadeIn={false}
        fluid={data[promoKey].childImageSharp.fluid}
        alt={"You hero"}
      />
      <Logo
        loading={"eager"}
        fadeIn={false}
        fixed={data[`${promoKey}Logo`].childImageSharp.fixed}
        alt={"You hero"}
      />
      <CopyContainer>
        <H1 color={"WHITE"}>{heading}</H1>
        <StyledBodyText>{body}</StyledBodyText>
      </CopyContainer>
    </Root>
  )
}

export default PromoHero
