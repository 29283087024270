import React from "react"
import styled from "styled-components"

import { H2 } from "../components/Heading"
import BodyText from "../components/BodyText"
import { COLORS } from "../constants"

const Root = styled.div`
  max-width: 1200px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const StyledBodyText = styled(BodyText)`
  p {
    color: ${COLORS.BLUE};
  }
`

interface ITermsConditions {
  rest?: object
}

const TermsConditions: React.FC<ITermsConditions> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <H2 color={"BLUE"}>Terms & conditions</H2>
      <StyledBodyText>
        <p>
          Not valid for use with any other offer. Good through May 17th - 31st.
        </p>
      </StyledBodyText>
    </Root>
  )
}

export default TermsConditions
