import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import TermsConditions from "../partials/TermsConditions"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import PromoHero from "../partials/PromoHero"
import { LINKS } from "../constants"
import "../styles.css"

const KoboxPage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kobox | Run For Heroes</title>
        <meta name="description" content="Kobox" />
        <html lang="en" />
      </Helmet>
      <Nav />
      <PageSection backgroundColor={"BLUE"}>
        <PromoHero
          promoKey={"kobox"}
          heading={"£5 off a 5 class package at Kobox"}
          body={
            <>
              <p>To redeem your offer, you must:</p>
              <p>
                1. Create an account at{" "}
                <a target={"_blank"} rel={"noopener"} href={LINKS.KOBOX}>
                  koboxboxingclub.com
                </a>
                .
              </p>
              <p>2. Save a card on file.</p>
              <p>
                3. Email{" "}
                <a href={"mailto:issey@unitedfitnessbrands.com"}>
                  Issey@unitedfitnessbrands.com
                </a>{" "}
                quoting ‘5k May special offer - purchase 5 classes’ with a proof
                of donation to the 5k May campaign.
              </p>
            </>
          }
        />
      </PageSection>
      <PageSection backgroundColor={"WHITE"}>
        <TermsConditions />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default KoboxPage
